<style scoped>
.not_use {
    background-color: #f4f4f4;
    color: #777777;
}
</style>

<template>
    <div>
        <v-card outlined style="box-shadow: none;" v-for="(companyIntro, companyIntroIdx) in companyIntroList" :key="'company_intro_' + companyIntroIdx">
            <v-card-title class="pl-7 pt-5 block-title" v-if="companyIntroIdx == 0">
                <v-row class="align-center">
                    <v-col class="pt-1">소개정보</v-col>
                    <v-col class="pt-1 text-right" v-if="companyIntroList.length < 3">
                        <v-btn outlined rounded class="text-h6" color="primary" @click="addIntro">
                            <v-icon left dark size="25">
                                mdi-plus-circle
                            </v-icon>
                            추가하기
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="pt-0 pl-0 pr-0 pb-0">
                <v-simple-table :class="{'not_use': wvUseYn == 'N'}">
                    <tr>
                        <td class="pt-5 pl-7 pr-7 pb-5 block-line-head block-br block-bb" style="width:200px;">소개 이미지(0/3)</td>
                        <td class="pt-0 pl-7 pr-7 pb-5 block-bb">
                            <v-row>
                                <v-col cols="12" class="pt-0">
                                    <template v-for="(imageUrl, imageUrlIdx) in companyIntro.image_url_list">
                                        <v-btn outlined class="mr-7 mt-5 pa-0" color="grey" style="width:300px; height:200px; float:left;" :key="'image_url_' + companyIntroIdx + '_' + imageUrlIdx">
                                            <v-img
                                                :src="imageUrl"
                                                aspect-ratio="1.5"
                                                class="grey lighten-2"
                                                v-on:click="openImgPopup(imageUrl)"
                                            >
                                                <v-row class="text-right lightbox white--text pt-0 pr-2 fill-height">
                                                    <v-col>
                                                        <v-btn fab x-small color="yellow" v-on:click="deleteImage(companyIntroIdx, imageUrlIdx)">X</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </v-btn>
                                    </template>

                                    <image-input-card v-model="tmpImage" @beforePicker="tmpImageIdx = companyIntroIdx" @input="uploadTmpImage" v-if="companyIntro.image_url_list.length < 3"
                                                      style="float:left;">
                                        <div slot="activator">
                                            <v-btn outlined class="mr-7 mt-5" color="grey" style="width:300px; height:200px;">
                                                <v-icon color="#4E87DD" class="mr-2">mdi-plus-circle</v-icon>
                                                <span style="color:#555555; font-size:16px; font-weight:600;">이미지 추가하기</span>
                                            </v-btn>
                                        </div>
                                    </image-input-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <span style="color:#999999; font-size:16px;">※ 소개 이미지는 최대 3장까지, 600X400 권장, 파일 용량은 각 5MB까지 등록가능</span>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                    <tr>
                        <td class="pt-5 pl-7 pr-7 pb-5 block-line-head block-br " style="width:200px;">소개글</td>
                        <td class="pt-5 pl-7 pr-7 pb-5 ">
                            <v-textarea outlined hide-details placeholder="글을 입력해 주세요" v-model="companyIntro.intro_text">
                            </v-textarea>
                        </td>
                    </tr>
                    <tr v-if="companyIntroList.length > 1">
                        <td colspan="2" class="pt-5 pb-5 text-center">
                            <v-btn outlined rounded class="text-h6" color="orange" @click="delIntro(companyIntroIdx)">
                                <v-icon left dark size="25">
                                    mdi-minus-circle
                                </v-icon>
                                삭제
                            </v-btn>
                        </td>
                    </tr>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="modal" persistent max-width="650">
            <v-flex v-if="dialogMode === 'img'">
                <img-preview-popup :url="popupImgUrl" v-on:cancel="closePopup"></img-preview-popup>
            </v-flex>
        </v-dialog>
    </div>
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import svcWvApi from '@/api/svc_wv'

export default {
    name: 'svc_wv_company_intro',
    components: {
        'imgPreviewPopup': () => import('@/components/svc/wv/popup_img_preview'),
        'imageInputCard': () => import('@/components/svc/wv/tile_image_input'),
    },
    data: () => ({
        tmpImage: null,
        tmpImageIdx: -1,
        modal: false,
        dialogMode: 'none',
        popupImgUrl: '',
        delImageClicked: false,
    }),
    created() {
    },
    mounted() {
    },
    computed: {
        ...mapState('svcWv', {
            wvUseYn: state => state.wvUseYn,
            companyIntroList: state => state.companyIntroList,
        }),
    },
    methods: {
        addIntro() {
            this.companyIntroList.push({"image_url_list": [], "intro_text": ""})
        },
        delIntro(idx) {
            this.companyIntroList.splice(idx, 1)
        },
        uploadTmpImage(payload) {
            let va = this
            svcWvApi.uploadImage(payload.imageFile, (data) => {
                if (data.content_url) {
                    //this.company.company_image_url = data.content_url
                    va.companyIntroList[va.tmpImageIdx].image_url_list.push(data.content_url)
                }
            }, (err) => {
                console.log('FAILURE!! : ', err);
                this.$noSessionProc(err)
            })
        },
        openImgPopup(url) {
            if (this.delImageClicked) {
                this.delImageClicked = false
                return
            }

            if (url) {
                this.popupImgUrl = url
                this.dialogMode = 'img'
                this.modal = true
            }
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'NONE'
        },
        deleteImage(introIdx, imageIdx) {
            if (this.companyIntroList && this.companyIntroList.length > introIdx && this.companyIntroList[introIdx].image_url_list && this.companyIntroList[introIdx].image_url_list.length > imageIdx) {
                this.companyIntroList[introIdx].image_url_list.splice(imageIdx, 1)
            }

            this.delImageClicked = true
            return false
        },
    }
}</script>